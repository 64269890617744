/** @module PC/Login */
import "./Login.Dialog.css";
import { doConnectLoginOut } from "../doLogout";
import { getProjectAppId } from "../CommonParam";
import { Base64 } from "../../encode/encode";
import * as cookie from "../../cookie/entry";
import { getLocation } from "../common";
/*
 * 该文件禁止使用$
 * */
const LOGIN$ = window.jQuery || window.Zepto;

import * as domainUtils from "../../../../lib/domain";
const mainDomain = domainUtils.getSogouOrSogo();
const domainType = domainUtils.getDomainType();

/**
 * 参数配置对象
 * @ignore
 */
const config = {};
const $win = LOGIN$(window);
const _width = 550; //iframe宽度
const _height = 360; //iframe高度

/***
 * 登陆框封装类
 */
class LoginDialog {
	/**
	 * @param {string} url - 完整的PTLoginURL
	 */
	constructor(url) {
		this.url = url;
		const _this = this;
		const domainAElement = document.createElement("a");
		domainAElement.href = window.location.href;
		let isLvshiOrZhuanjiaOrKFOrTaowen = false;
		let isWenwenOrBaike = false;
		if (
			domainAElement.host.indexOf("lvshi.sogou.com") > -1 ||
			domainAElement.host.indexOf("zhuanjia.sogou.com") > -1 ||
			domainAElement.host.indexOf("kf.sogou.com") > -1
		) {
			isLvshiOrZhuanjiaOrKFOrTaowen = true;
		} else if (
			domainAElement.host.indexOf("wenwen.sogou.com") > -1 ||
			domainAElement.host.indexOf("baike.sogou.com") > -1
		) {
			isWenwenOrBaike = true;
		}

		if (!LOGIN$("#login_iframe_container").length) {
			const loginDialogTplArr = [];

			const wrapClassName =
				"new-login " + (isWenwenOrBaike ? " no-bg" : "");

			loginDialogTplArr.push(
				'<div id="login_iframe_container" class="' +
					wrapClassName +
					'" style="top:' +
					($win.height() - _height) / 2 +
					"px;left:" +
					($win.width() - _width) / 2 +
					'px;">'
			);
			loginDialogTplArr.push('<i class="close-btn loginClose"></i>');
			loginDialogTplArr.push(
				'<div class="login-title">快速安全登录</div>'
			);
			loginDialogTplArr.push(
				`<div class="login-subtitle"><span>使用第三方账户</span></div>`
			);
			loginDialogTplArr.push(
				'<a id="qqLogin" data-link="' +
					this.url +
					'" class="login-btn qq-btn login-btn-gray"><div class="login-icon"></div><div class="login-text">QQ登录</div></a>'
			);
			if (!isLvshiOrZhuanjiaOrKFOrTaowen) {
				loginDialogTplArr.push(
					'<a id="wxLogin" data-link="' +
						_this.generateWeixinLoginUrl() +
						'" class="login-btn wechat-btn login-btn-gray"><div class="login-icon"></div><div class="login-text">微信登录</div></a>'
				);
			}
			loginDialogTplArr.push(
				`<div class="login-protocol-tips"><span>请阅读并勾选用户协议和隐私政策</span></div><label for="loginProtocol" class="login-protocol">
					<input class="login-protocol-input" type="checkbox" id="loginProtocol" name="scales">阅读并同意<a target="_blank" href="https://baike.sogou.com/help/#user_protocol">《用户协议》</a>和<a target="_blank" href="https://baike.sogou.com/help/#sogou_privacy_protocol">《隐私政策》</a>
				</label>`
			);
			loginDialogTplArr.push("</div>");

			LOGIN$("body").append(
				'<div id="login_mask" style="width:100%"></div>' +
					loginDialogTplArr.join("")
			);
		}

		const instance = this;

		LOGIN$("#login_iframe_container").on(
			"click",
			".loginClose",
			function () {
				instance.close();
			}
		);

		//窗口大小变化时重新计算弹窗位置
		let scrollTimer = -1;
		LOGIN$(window).on("resize", function (e) {
			if (typeof scrollTimer === "undefined") {
				return false;
			}

			if (scrollTimer) {
				clearTimeout(scrollTimer);
			}

			scrollTimer = setTimeout(function () {
				LOGIN$("#login_iframe_container").css({
					top: ($win.height() - _height) / 2 + "px",
					left: ($win.width() - _width) / 2 + "px",
				});
			}, 250);
		});
	}

	/**
	 * 关闭登录框
	 */
	close() {
		LOGIN$("#login_mask").remove();
		LOGIN$("#login_iframe_container").remove();
		window.WKSSO.callbackList = []; //为解决多次点击关闭按钮时，callbackList存储多余的回调
	}

	generateWeixinLoginUrl() {
		let localDomainType = domainUtils.getDomainType();
		const appidConfig = {
			baike: "wxfe4829624efe75f1",
			wenwen: "wxcb619c36a67960d4",
			wenda: "wxcb619c36a67960d4",
			zhinan: "wxf80d5463ed29d9ae",
			ld: "wxff36fd3eba5a923f",
			kexue: "wx0d95b15d7aca66b3",
		};

		//略懂改名为搜狗问问后，登录id用同一个
		if (localDomainType === "ld") {
			localDomainType = "wenwen";
		}

		const redirect_uri =
			"https://wenwen.sogou.com/login/wechat/save_user_info?tp=" +
			localDomainType +
			"_site&business=" +
			localDomainType +
			"&redirect=" +
			encodeURIComponent(window.location.href);

		return (
			"https://open.weixin.qq.com/connect/qrconnect?appid=" +
			appidConfig[localDomainType] +
			"&redirect_uri=" +
			encodeURIComponent(redirect_uri) +
			"&response_type=code&scope=snsapi_login&state=3d6be0a4035d839573b04816624a415e&self_redirect=true#wechat_redirect"
		);
	}
}

/**
 * 登录功能封装类
 * @class
 */
export class Login {
	/**
	 * @param {string} domain - 生产的Login对象host
	 */
	constructor() {
		this.loginDialog = null; //登录对话框对象
		this.callbackList = []; //登录后的回调函数List
	}

	/**
	 * 合成注销登录的URL
	 * @param {string} domain - 当前业务线的域名
	 * @returns {string}
	 */
	static generateLoginOutUrl() {
		const {protocol, hostname, pathPrefix } = getLocation();
		return `${protocol}//${hostname}${pathPrefix}/login/popLogout`;
	}

	/**
	 * 检测登录态的URL
	 * @returns {string}
	 */
	static ajaxLoginStateUrl() {
		if (domainType === "ld") {
			return "/wapi/login/ld-loginState";
		} else {
			return "/login/loginState";
		}
	}

	/**
	 * 执行登录操作
	 * @param {function} callback  用户登录后的回调操作，也可跳转到指定的url
	 * @param {string} autoClose 登录成功后自动关闭，必须由window.open方式打开
	 */
	doLogin(callback, autoClose) {
		const appId = getProjectAppId[domainType];
		const { hostname, pathPrefix } = getLocation();
		const redirectUri =
			"https://"+ hostname + pathPrefix +"/login/qq/user_info?business=" +
			domainType +
			"&return_url=" +
			Base64.ieBase64(window.location.href);

		const url =
			"https://graph.qq.com/oauth2.0/show?which=Login&display=pc&response_type=code&client_id=" +
			appId +
			"&state=" +
			new Date().getTime() +
			"&redirect_uri=" +
			encodeURIComponent(redirectUri);

		if (autoClose) {
			config.autoClose = true;
		}

		this.openLoginDialog(url);

		const key = this.callbackList.length;
		if (typeof callback === "function") {
			this.callbackList[key] = callback;
		} else if (typeof callback === "string") {
			this.callbackList[key] = function () {
				window.location.href = callback;
			};
		}
	}

	/**
	 * 执行注销登录操作
	 * 1、注销PTLogin的登录
	 * 2、删除.sogou.com/.sogo.com域下的p_skey, p_uin或者sg_lu, sg_mu
	 * @param {function} callback - 退出登录后的callback操作，默认会进行页面刷新
	 */
	doLoginOut(callback) {
		const url = Login.generateLoginOutUrl();
		doConnectLoginOut(url, callback);
	}

	/**
	 * 打开登录弹框
	 * @param {string} url - 登陆框Iframe的src地址
	 */
	openLoginDialog(url) {
		this.loginDialog = new LoginDialog(url);
	}

	/**
	 * 登录后Iframe回跳到/login/popLogin
	 * popLogin和调用页进行登录信息和弹窗关闭的通信
	 * 然后调用autoCloseLoginDialog关闭登录框, 因此调用关闭登录框的页面是在popLogin页面
	 */
	autoCloseLoginDialog() {
		LOGIN$("#login_mask").remove();
		LOGIN$("#login_iframe_container").remove();
		const callbackList = this.callbackList;
		if (callbackList && callbackList.length) {
			for (let i = 0, cbLen = callbackList.length; i < cbLen; i++) {
				const callback = this.callbackList[i];
				if (callback && typeof callback === "function") {
					callback();
				}
			}
			//执行后清除回调
			this.callbackList = [];
		}
	}

	/**
	 * 检查登录态, 如果接口失败，则以未登录状态做回调
	 * @param {function} outCallback - 当前未登录回调
	 * @param {function} loginCallback - 当前已登录回调
	 */
	checkLoginState(outCallback, loginCallback, tagId) {
		const instance = this;
		const url = Login.ajaxLoginStateUrl();
		let data = {};
		if (tagId != "undefined" && LOGIN$.isArray(tagId)) {
			data = { tagIds: tagId.join(",") };
		}
		LOGIN$.ajax({
			url: url,
			type: "get",
			dataType: "json",
			data: data,
			cache: false,
			success: function (data) {
				if ((data && data.code == 1) || data == 1) {
					// 修改页面的 _gtag 的一些必要变量
					if (data.user && typeof data.user === "object") {
						instance.modifyPageGtagValue(data);
					}
					if (typeof loginCallback === "function") {
						loginCallback(data);
					}
				} else if ((data && data.code == 0) || data == 0) {
					if (typeof outCallback === "function") {
						outCallback();
					}
				}
			},
			error: function () {
				if (typeof outCallback === "function") {
					outCallback();
				}
			},
		});
	}

	/**
	 * 确保用户登录后
	 * 执行回调, 如果用户没有登录
	 * 会调用登录操作，然后执行回调， 用户已登录则直接执行回调
	 * @param {function} callback - 回调函数
	 */
	insureLoginAction(loginCallback, outCallback, tagId) {
		this.checkLoginState(
			() => {
				let param = loginCallback;
				if (typeof outCallback === "function") {
					param = outCallback;
				}
				this.doLogin(param);
			},
			(userData) => {
				if (typeof loginCallback === "function") {
					loginCallback(userData);
				}
			},
			tagId
		);
	}

	/**
	 * 登录之后需要执行回调函数时可以用这个方法，例如：登录后自动更新头部信息
	 * @param {function} callback - 回调函数
	 * @example
	 * WKSSO.appendCallbackList(callback);   //callback为登录之后需要执行的回调函数
	 */
	appendCallbackList(callback) {
		if (callback && typeof callback === "function") {
			this.callbackList.push(callback);
		}
	}

	/**
	 * 修改页面的 _gtag 的一些必要变量
	 */
	modifyPageGtagValue(data) {
		const user = data.user;
		if (window._gtag) {
			if (typeof _gtag.isLogin !== "undefined") {
				_gtag.isLogin = true;
			}
			if (typeof _gtag.userId !== "undefined" && user.userId) {
				_gtag.userId = user.userId;
			}
			if (typeof _gtag.uid !== "undefined" && user.uid) {
				_gtag.uid = user.uid;
			}
			if (typeof _gtag.userName !== "undefined" && user.userName) {
				_gtag.userName = user.userName;
			}
			if (typeof _gtag.userHead !== "undefined") {
				if (user.userHead && user.userHead !== "") {
					_gtag.userHead = user.userHead;
				} else {
					_gtag.userHead =
						"//cache.soso.com/wenwen/i/default-thumb.png"; //wenwen默认头像
				}
			}
		}
	}
}

/**
 * 获取Login实例的工厂方法
 * 采用单例模式，保证整个页面下获取的Login是唯一实例
 * @function
 * @param {string} type - 生成登录实例的域名
 */
export const pcCreateLoginFactory = (function () {
	let instance = null;
	return function () {
		if (!instance) {
			instance = new Login();
		}
		return instance;
	};
})();
