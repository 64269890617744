/**
 * 获取业务类型
 * @returns {string}
 */
export function getDomainType() {
	let domain = "wenwen";
	const href = location.href.toLowerCase();
	let host = location.hostname.toLowerCase();
	if (host.match(/\./g).length == 3) {
		host = host.slice(host.indexOf(".") + 1);
	}
	switch (host) {
		case "wenwen.sogou.com":
		case "wenwen.sogo.com":
		case "wenwen.m.sogou.com":
		case "vop.woa.com":
			domain = "wenwen";
			break;
		case "ld.sogo.com":
		case "ld.sogou.com":
			domain = "ld";
			break;
		case "zhinan.sogo.com":
		case "zhinan.sogou.com":
			domain = "zhinan";
			break;
		case "baike.sogo.com":
		case "baike.sogou.com":
		case "baike.m.sogou.com":
		case "baike.woa.com":
			if (href.indexOf(host + "/kexue") > -1) {
				domain = "kexue";
			} else {
				domain = "baike";
			}
			break;
		case "zhuanjia.sogou.com":
		case "zhuanjia.sogo.com":
			domain = "zhuanjia";
			break;
		case "lvshi.sogou.com":
		case "lvshi.sogo.com":
		case "lawyer.sogou.com":
		case "lawyer.sogo.com":
			domain = "lvshi";
			break;
		default:
			break;
	}

	return domain;
}

/**
 * 处理Host中应该为sogou还是sogo
 * @returns {string}
 */
export function getSogouOrSogo() {
	return location.host.indexOf(".sogou.com") > -1 ? "sogou" : "sogo";
}
