/** @module doLogout */
/**
 * 请求腾讯侧注销登录凭证， 这一步不可缺少， 否则p_skey凭证依然可以在登录
 * 调用腾讯PTLogin提供的JS通用退出登录方法
 * 首先动态加载ptloginout脚本， 然后调用logoutQQCom方法
 * @function
 * @param callback
 */
export function doPTLoginOut(callback) {
	if (
		typeof pt_logout !== "undefined" &&
		typeof pt_logout.logoutQQCom === "function"
	) {
		pt_logout.logoutQQCom(function (code) {
			//code: 0:失败；1：清除superkey,兼容旧版；2：清除superskey成功，且logout成功。
			callback();
		});
	} else {
		let loadScript;
		const url = "//imgcache.qq.com/ptlogin/ac/v9/js/ptloginout.js";
		loadScript = document.createElement("script");
		loadScript.setAttribute("type", "text/javascript");
		loadScript.onload =
			loadScript.onreadystatechange =
			loadScript.onerror =
				function () {
					if (loadScript.ready) {
						return false;
					}
					if (
						!loadScript.readyState ||
						loadScript.readyState == "loaded" ||
						loadScript.readyState == "complete"
					) {
						loadScript.ready = true;
						if (
							typeof pt_logout !== "undefined" &&
							typeof pt_logout.logoutQQCom === "function"
						) {
							pt_logout.logoutQQCom(function (code) {
								//code: 0:失败；1：清除superkey,兼容旧版；2：清除superskey成功，且logout成功。
								callback();
							});
						}
					}
				};
		loadScript.setAttribute("src", url);
		document.body.appendChild(loadScript);
	}
}

/**
 * 请求搜狗侧业务注销登录cookie
 * @function
 * @param url 对应业务线的注销登录地址
 * @param callback 请求发送成功的回调
 */
export function doWKLoginOut(url, callback) {
	const img = new Image();
	callback = callback || location.href;
	const callBackFunc = function () {
		if (typeof callback === "function") {
			callback();
		} else if (
			!callback ||
			(typeof callback === "string" && callback.length)
		) {
			//如果callback为空或者跳转链接是改变hash地址， 执行刷新操作
			if (
				!callback ||
				location.href.split("#").shift() === callback.split("#").shift()
			) {
				if (/MicroMessenger/i.test(window.navigator.userAgent)) {
					let tempUrl = location.href;
					if (tempUrl.indexOf("?") > -1) {
						//有参数
						tempUrl = tempUrl.replace(
							/[?]/,
							"?_t=" + Math.random() + "&"
						);
					} else if (tempUrl.indexOf("#") > -1) {
						//无参数有hash
						tempUrl = tempUrl.replace(
							/[#]/,
							"?_t=" + Math.random() + "#"
						);
					} else {
						tempUrl = tempUrl + "?_t=" + Math.random();
					}
					window.location.href = tempUrl;
				} else {
					location.reload(true);
				}
			} else {
				location.href = callback;
			}
		}
	};
	img.onload = function () {
		callBackFunc();
		img.onload = img.onerror = null;
	};
	//出错直接刷新页面？
	img.onerror = function () {
		callBackFunc();
		img.onload = img.onerror = null;
	};
	img.src = url;
}

/**
 * 注销登录凭证
 * 1、先注销PTLogin登录凭证
 * 2、注销搜狗侧登录凭证
 * @function
 * @param {string} url 搜狗侧对应业务线的注销登录地址
 * @param {function} callback 注销后回调
 */
let ietimer = 0;

export function doConnectLoginOut(url, callback) {
	doPTLoginOut(function () {
		clearTimeout(ietimer);
		doWKLoginOut(url, callback);
	});

	//IE8下浏览器, doPTLoginOUt, 内部执行出错， 并且无法捕获异常
	ietimer = setTimeout(function () {
		doWKLoginOut(url, callback);
	}, 3000);
}
