import { pcCreateLoginFactory } from "./Login.PC";
/*
 * 该文件禁止使用$
 * */
const MAIN$ = window.jQuery || window.Zepto;
const domain = location.hostname;

try {
	document.domain =
		location.hostname.indexOf("sogou.com") > -1 ? "sogou.com" : "sogo.com";
} catch (e) {}

MAIN$(function () {
	const login = pcCreateLoginFactory(domain);
	/***
	 * 登录元素绑定
	 *
	 */
	MAIN$(document).on(
		"click",
		"#login, #s_login, .s_login, .j-login",
		function (e) {
			e.preventDefault();
			e.stopPropagation();
			let cb;
			//默认登录刷新当前页， 如果有login_noreload则不刷新
			if (!MAIN$(this).hasClass("login_noreload")) {
				cb = function () {
					location.reload(false);
				};
			}
			login.doLogin(cb);
			return false;
		}
	);
	/***
	 * 退出登录元素绑定
	 *
	 */
	MAIN$(document).on(
		"click",
		"#logout, .logout, #s_logout,.sogou_logout, .j-logout",
		function (e) {
			e.preventDefault();
			e.stopPropagation();
			//点击目标带有href属性， 则退出成功后跳转到该url
			const cb = MAIN$(this).attr("s_url");
			login.doLoginOut(cb);
			return false;
		}
	);
	/***
	 * qq\wx登录
	 */
	MAIN$(document).on(
		"click",
		"#qqLogin,#wxLogin",
		function () {
			const tips = MAIN$('.login-protocol-tips>span');
			// 没勾选
			const checked = MAIN$('#loginProtocol').prop('checked');
			if (!checked) {
				tips.show();
			}
		}
	);
	/***
	 * 用户协议勾选
	 */
	MAIN$(document).on(
		"click",
		"#loginProtocol",
		function () {
			const checked = MAIN$(this).prop('checked');
			const qqBtn = MAIN$('#qqLogin');
			const wxBtn = MAIN$('#wxLogin');
			const tips = MAIN$('.login-protocol-tips>span');
			const qqLoginUrl = qqBtn.attr('data-link');
			const wxLoginUrl = wxBtn.attr('data-link');
			if (checked) {
				qqBtn.attr('href', qqLoginUrl);
				qqBtn.removeClass('login-btn-gray');
				wxBtn.attr('href', wxLoginUrl);
				wxBtn.removeClass('login-btn-gray');
				tips.hide();
			} else {
				qqBtn.attr('href', 'javascript:void(0);');
				qqBtn.addClass('login-btn-gray');
				wxBtn.attr('href', 'javascript:void(0);');
				wxBtn.addClass('login-btn-gray');
			}
		}
	);
});

/**
 * PC操作登录实例
 * @name WKSSO
 * @global
 * @type {module:PC/Login~Login}
 * @see module:PC/Login~Login
 */
window.WKSSO = pcCreateLoginFactory(domain);
