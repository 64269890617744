import "./wenke/main";

const ua = window.navigator.userAgent;
if (/iphone|ipad|android|MiniProgramEnv/i.test(ua)) {
	import("./connectLogin/wap/main");
} else {
	require("./connectLogin/pc/main");
}

const ajax$ = window.jQuery || window.Zepto;
ajax$(function () {
	const idataScriptTagList = ajax$('script[src*="idata"]');

	if (!idataScriptTagList.length) {
		try {
			require("./idata");
		} catch (e) {
			// do nothing
		}
	}
});
