/**
 * Base64编码与解码，支持中文
 */
export class Base64 {
    constructor() {
    }

    /**
     * base64编码
     * @param input
     * @returns {string}
     */
    static encode(input) {
        return window.btoa(unescape(encodeURIComponent(input)))
    }

    /**
     * base64解码
     * @param input
     * @returns {string}
     */
    static decode(input) {
        return decodeURIComponent(escape(window.atob(input)));
    }
    static ieBase64(input) {
        let keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
        let output = "";
        let chr1, chr2, chr3, enc1, enc2, enc3, enc4;
        let i = 0;

        let utf8Encode = function (string) {
            string = string.replace(/\r\n/g, "\n");
            let utftext = "";
            for (let n = 0; n < string.length; n++) {
                let c = string.charCodeAt(n);
                if (c < 128) {
                    utftext += String.fromCharCode(c);
                } else if ((c > 127) && (c < 2048)) {
                    utftext += String.fromCharCode((c >> 6) | 192);
                    utftext += String.fromCharCode((c & 63) | 128);
                } else {
                    utftext += String.fromCharCode((c >> 12) | 224);
                    utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                    utftext += String.fromCharCode((c & 63) | 128);
                }

            }
            return utftext;
        };

        input = utf8Encode(input);
        while (i < input.length) {
            chr1 = input.charCodeAt(i++);
            chr2 = input.charCodeAt(i++);
            chr3 = input.charCodeAt(i++);
            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;
            if (isNaN(chr2)) {
                enc3 = enc4 = 64;
            } else if (isNaN(chr3)) {
                enc4 = 64;
            }
            output = output +
                keyStr.charAt(enc1) + keyStr.charAt(enc2) +
                keyStr.charAt(enc3) + keyStr.charAt(enc4);
        }

        return output;
    }
}

export class HTML {
    static encodeHTML(str) {
        if (typeof str === "string") {
            var div = $("<div></div>");
            var text = div.text(str).html();
            div = null;
            return text;
        }
    }

    static decodeHTML(str) {
        if (typeof str === "string") {
            var div = $("<div></div>");
            var text = div.html(str).text();
            div = null;
            return text;
        }
    }
}