import * as cookie from "../cookie/entry";
let originalCookieKey, originalCookieVal;
import { addCommonData } from "./origin";
import { getSogouOrSogo } from "../../../lib/domain";

/*
 * 该文件禁止使用$
 * */
const ajax$ = window.jQuery || window.Zepto;
const mainDomain = getSogouOrSogo();

function deleteCookieForSecurity() {
	const cookieKeyArr = ["qun_t0", "qun_t1", "qun_to", "qun_tl"];
	const cookieKeyArrLen = cookieKeyArr.length;

	if (cookieKeyArrLen > 0) {
		const rnd = Math.floor(Math.random() * cookieKeyArr.length);
		originalCookieKey = cookieKeyArr[rnd];
		originalCookieVal = cookie.getCookie(originalCookieKey);

		if (originalCookieVal) {
			cookie.delCookie(originalCookieKey, "." + mainDomain + ".com", "/");
		}
	}
}

function resetCookieForSecurity() {
	if (originalCookieKey && originalCookieVal) {
		cookie.setCookie(
			originalCookieKey,
			originalCookieVal,
			1,
			"." + mainDomain + ".com",
			"/"
		);
	}
}

/**
 *
 * @param {*} event
 * @param {*} xhr
 * @param {*} options
 */
function retryHandle(event, xhr = {}, options = {}) {
	if (!(typeof options.retry === "number" && options.retry)) {
		return false;
	}

	if (typeof options.retryCount === "undefined") {
		options.retryCount = 0;
	}

	options.retryCount++;

	if (options.retryCount <= options.retry) {
		ajax$.ajax(options);
	}
}

//用于防范用户多次点击导致发送多次ajax请求
window.ajaxSingleton = {};
//ajax接口耗时上报
window.ajaxSpentTime = {};

if (typeof console !== "object") {
	window.console = {};
	console.log = function () {};
}

/**
 *
 * @param url
 * @param isHostname  true:获取hostname 域名
 * @returns {string}
 */
function getPathname(url, isHostname) {
	const a = document.createElement("a");
	a.href = url;
	const hostname = a.hostname;
	const search = a.search || "";
	const params = search.split("&");
	const filterParams = [];
	for (let i = 0; i < params.length; i++) {
		const param = params[i];
		if (!(param.indexOf("_=") > -1 || param.indexOf("_traceId=") > -1)) {
			filterParams.push(param);
		}
	}
	if (isHostname) {
		return hostname;
	} else {
		return (
			a.pathname +
			(search.indexOf("?") === -1 || params[0].indexOf("_") !== -1
				? "?"
				: "") +
			filterParams.join("&")
		);
	}
}

/**
 * 如果要中止ajax请求，必须要return false;
 * @param xhr
 * @param options
 */
function beforeSendHandler(xhr, options = {}, beforeAction) {
	beforeAction(xhr, options);
	if (options.dataType === "jsonp") {
		return false;
	}

	const url = options.url;
	const INVALID_URL_BLANK_PREFIX = /^(\s+)\/.*/;
	const INVALID_URL_BLANK_SUFFIX = /.*(\s+)$/;
	if (
		url.match(INVALID_URL_BLANK_PREFIX) ||
		url.match(INVALID_URL_BLANK_SUFFIX)
	) {
		//url前后不能包含空格，否则在手机QQ浏览器会报错
		throw new Error(
			'ajax请求url非法, 不能包含空格, url: "' + options.url + '"'
		);
		// return false;
	}

	let type = options.type || "get";
	type = type.toLowerCase();
	//在options中允许重复
	if (options.bsRepeat === true) {
		options.url +=
			(options.url.indexOf("?") > -1 ? "&rnd=" : "?rnd=") + Math.random();
	}

	let stopRequest = false;
	const pathname = getPathname(options.url);
	if (window.ajaxSingleton[pathname]) {
		stopRequest = true;
	} else {
		if (type === "post") {
			deleteCookieForSecurity();
		}
		window.ajaxSingleton[pathname] = true;
		window.ajaxSpentTime[pathname] = new Date().getTime();
	}
	return stopRequest;
}

if (ajax$) {
	ajax$(document).on(
		"ajaxError",
		function (event, xhr = {}, options = {}, _response) {
			let response = _response;
			if (typeof response === "string") {
				response = response.toLowerCase();
				//ZeptoJS返回abort，jQuery返回canceled
				if (
					response === "abort" ||
					response === "canceled" ||
					response === "cancel"
				) {
					return false;
				}
			}

			const type = options.type;
			if (type && type.toLowerCase() === "post") {
				resetCookieForSecurity();
			}

			let url = xhr.url || options.url || "";
			const pathname = getPathname(url);
			window.ajaxSingleton[pathname] = false;
			window.ajaxSpentTime[pathname] =
				new Date().getTime() - window.ajaxSpentTime[pathname];

			retryHandle(event, xhr, options);

			if (
				url.indexOf("//") === -1 &&
				url.toLowerCase().indexOf("http:") === -1 &&
				url.toLowerCase().indexOf("https:") === -1
			) {
				url = location.protocol + "//" + location.host + url;
			}

			try {
				if (type.toLowerCase() === "post") {
					const postData = options.data || "No post data";
					const reportObj = {
						type: "ajaxPOST",
						url: url,
						requestData: postData,
						responseText: {
							//错误状态
							readyState: xhr.readyState,
							status: xhr.status,
							statusText: xhr.statusText,
							responseText: response,
						},
						stack: "ajaxError",
					};
					window._gtag?.aegis?.report({
						msg: JSON.stringify(reportObj),
						level: Aegis?.logType?.AJAX_ERROR,
					});
				} else if (type.toLowerCase() === "get") {
					let search = "";
					if (url.indexOf("?") > -1) {
						search = url.split("?")[1]; //获取url中"?"符后的字串
					}
					const reportObj = {
						type: "ajaxGET",
						url: url.indexOf("?") > -1 ? url.split("?")[0] : url,
						requestData: search,
						responseText: {
							//错误状态
							readyState: xhr.readyState,
							status: xhr.status,
							statusText: xhr.statusText,
							responseText: response,
						},
						stack: "ajaxError",
					};
					window._gtag?.aegis?.report({
						msg: JSON.stringify(reportObj),
						level: Aegis?.logType?.AJAX_ERROR,
					});
				}
			} catch (e) {
				// do nothing
			}
		}
	);

	ajax$(document).on("ajaxSuccess", function (event, xhr = {}, options = {}) {
		const type = options.type;
		if (type && type.toLowerCase() === "post") {
			resetCookieForSecurity();
		}
		let url = xhr.url || options.url || "";
		const pathname = getPathname(url);
		window.ajaxSingleton[pathname] = false;
		window.ajaxSpentTime[pathname] =
			new Date().getTime() - window.ajaxSpentTime[pathname];

		if (
			url.indexOf("//") === -1 &&
			url.toLowerCase().indexOf("http:") === -1 &&
			url.toLowerCase().indexOf("https:") === -1
		) {
			url = location.protocol + "//" + location.host + url;
		}
	});

	//增加对用户多次点击的判断，如果上一个请求还未完成，则禁止发送下一次请求
	if (ajax$.ajaxSetup) {
		//jQuery
		ajax$.ajaxSetup({
			cache: false,
			beforeSend: function (xhr, options) {
				const stopRequest = beforeSendHandler(
					xhr,
					options,
					addCommonData
				);
				if (stopRequest) {
					return false;
				}
			},
		});
	} else {
		//ZeptoJS
		ajax$.ajaxSettings["cache"] = false;
		ajax$.ajaxSettings["beforeSend"] = function (xhr, options) {
			const stopRequest = beforeSendHandler(xhr, options, addCommonData);
			if (stopRequest) {
				return false;
			}
		};
	}
}
/*
 * 该文件禁止使用$
 * */
