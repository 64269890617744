/**
 * 设置某个cookie的值
 * @param {string} key cookie的名称
 * @param {string} value cookie的值
 * @param {integer} [hours] 超时时间  如果不提供cookie的随浏览器进程存在，浏览器关闭则cookie失效
 * @param domain
 * @param path
 * @return {string} cookie信息
 */
export function setCookie(name, value, hours, domain, path) {
	let expire = "";

	if (hours instanceof Date) {
		expire = "; expires=" + hours.toGMTString();
	} else if (hours != null) {
		expire = new Date(new Date().getTime() + hours * 3600000);
		expire = "; expires=" + expire.toGMTString();
	}

	if (typeof domain === "undefined") {
		domain = "";
	} else {
		domain = "; domain=" + domain;
	}

	if (typeof path === "undefined") {
		path = "";
	} else {
		path = "; path=" + path;
	}

	document.cookie =
		name + "=" + encodeURIComponent(value) + expire + domain + path;
}

/**
 * 获取某个cookie的值
 * @param {string} key cookie的名称
 * @return {string} cookie值
 */
export function getCookie(key) {
	var key = encodeURIComponent(key);
	const cookies = document.cookie.split(";");
	for (let i = 0, len = cookies.length; i < len; i++) {
		let parts = cookies[i].split("="),
			name = decodeURIComponent(parts.shift()),
			cookie = parts.join("=");

		if (key === name.replace(/^\s+|\s+$/g, "")) {
			try {
				return decodeURIComponent(cookie);
			} catch (e) {
				//兼容历史base库中用escape编码的cookie
				cookie = unescape(cookie);
				Cookie.setCookie(name, cookie);
				return cookie;
			}
		}
	}
	return "";
}

/**
 * 删除cookie
 * @param {string} key cookie名称
 * @param domain
 * @param path
 */
export function delCookie(name, domain, path) {
	const expire = "; expires=Mon, 26 Jul 1997 05:00:00 GMT";

	if (typeof domain === "undefined") {
		domain = "";
	} else {
		domain = "; domain=" + domain;
	}

	if (typeof path === "undefined") {
		path = "";
	} else {
		path = "; path=" + path;
	}

	document.cookie = name + "=" + expire + domain + path;
}
