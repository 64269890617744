import { getSogouOrSogo } from "../../../lib/domain";

const mainDomain = getSogouOrSogo();
let haveTraceid = false;
let traceId = "";
let _traceId = ""; // 带有index
const traceIdIndexName = "traceIdIndex";
let aresFlag = false; // 防止多次判断isAres
let haveToken = false;
let csrfToken = "";

if (window._gtag && _gtag.traceId) {
	haveTraceid = true;
	traceId = _gtag.traceId;
}

if (window._gtag && _gtag.csrfToken) {
	haveToken = true;
	csrfToken = _gtag.csrfToken;
}

export function addCommonData(xhr, settings) {
	const mobileRegex = /android|iphone/gi;
	const ua = window.navigator.userAgent;
	const isMobile = mobileRegex.test(ua);
	const isAres =
		window.__ARES__ && typeof window.__ARES__.aresid !== "undefined";

	if (!aresFlag) {
		aresFlag = true;
		if (isAres) {
			haveTraceid = true;
			traceId = window.__ARES__.aresid;
		}
	}

	if (haveTraceid) {
		if (typeof window[traceIdIndexName] === "undefined") {
			window[traceIdIndexName] = 1;
		} else {
			window[traceIdIndexName]++;
		}

		_traceId = traceId + ":" + window[traceIdIndexName];
	}

	if (
		(location.hostname.indexOf("ld." + mainDomain + ".com") > -1 ||
			location.href.indexOf("wenwen." + mainDomain + ".com/mobile") >
				-1) &&
		isMobile
	) {
		const origin =
			location.hostname.indexOf("ld." + mainDomain + ".com") > -1 ? 4 : 1;
		const orig = isMobile ? 254 : 253;
		let data = settings.data;
		const type = settings.type;
		const contentType = settings.contentType;
		var url;

		if (type.toLowerCase() === "post") {
			if (
				contentType &&
				contentType.toLowerCase().indexOf("application/json") > -1 &&
				data
			) {
				try {
					data = JSON.parse(data);
				} catch (e) {}

				//data = JSON.parse(data);
				if (
					typeof data === "object" &&
					typeof data.origin === "undefined"
				) {
					data.origin = origin;
				}

				if (
					typeof data === "object" &&
					typeof data.orig === "undefined"
				) {
					data.orig = orig;
				}

				data = JSON.stringify(data);
				settings.data = data;
			} else if (
				!contentType ||
				contentType
					.toLowerCase()
					.indexOf("application/x-www-form-urlencoded") > -1
			) {
				url = settings.url;

				if (!data && url.indexOf("origin=") === -1) {
					if (url.indexOf("?") > -1) {
						url += "&origin=" + origin;
					} else {
						url += "?origin=" + origin;
					}
					settings.url = url;
				} else if (
					typeof data === "string" &&
					data.indexOf("origin=") === -1
				) {
					data += "&origin=" + origin;
				}

				if (!data && url.indexOf("orig=") === -1) {
					if (url.indexOf("?") > -1) {
						url += "&orig=" + orig;
					} else {
						url += "?orig=" + orig;
					}
					settings.url = url;
				} else if (
					typeof data === "string" &&
					data.indexOf("orig=") === -1
				) {
					data += "&orig=" + orig;
				}

				if (data) {
					settings.data = data;
				}
			}
		} else {
			url = settings.url;

			if (url.indexOf("orig=") === -1) {
				url.indexOf("?") === -1
					? (url += "?orig=254")
					: (url += "&orig=254");
			}

			if (url.indexOf("origin=") === -1) {
				url += "&origin=" + origin;
			}

			settings.url = url;
		}
	}

	if (haveTraceid) {
		url = settings.url;

		if (url.indexOf("_traceId=") === -1) {
			url.indexOf("?") === -1
				? (url += "?_traceId=" + _traceId)
				: (url += "&_traceId=" + _traceId);
			settings.url = url;
		}
	}

	//添加csrfToken
	if (haveToken) {
		url = settings.url;

		if (url.indexOf("c_tk=") === -1) {
			url.indexOf("?") === -1
				? (url += "?c_tk=" + csrfToken)
				: (url += "&c_tk=" + csrfToken);
			settings.url = url;
		}
	}
}
