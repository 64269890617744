export const getLocation = () => {
  const location = window.location;
  const protocol = location.protocol;
  const hostname = location.hostname;
  // 内网测试域名
  const isZhiNanTestDomain = hostname === 'test.zhinan.woa.com';
  const isBaikeAndWenWenTestDomain = /^test\.(?:baike|wenwen)\.woa\.com$/.test(hostname);
  const isPreviewDomain = /^pre\.(?:baike|wenwen|zhinan)\.woa\.com$/.test(hostname);
  // 内网域名
  const isWoaDomain = /\.woa\.com$/.test(hostname);
  const $hostname =  isWoaDomain ? 'api.wenwen.woa.com' : 'wenwen.sogou.com';
  const previewPath = isBaikeAndWenWenTestDomain || isPreviewDomain ? '/preview' : '';
  const pathPrefix = isZhiNanTestDomain ? '/test' : previewPath;
  return {
    protocol,
    hostname: $hostname,
    pathPrefix
  };
};