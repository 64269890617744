/**
 * loginFunc function  :if not login,so need to login
 * verifiedCallback function  :if the user has verified,then to do what
 * isExcuteVerfied bool: if not verified, then verify, complete, and excute the verifiedCallback auto?
 * closeCallback function: close the verify prompt callback
 * sync bool:  sync check verify(for baike)
 * successReport string: success verify report data
 */
const verifyFunc = function (
	loginFunc,
	verifiedCallback,
	isExcuteVerfied,
	closeCallback,
	sync,
	successReport
) {
	$.loadScript({
		url: "//hhy.sogoucdn.com/js/common/realNameVerify/main.js",
		success: function () {
			const verifyObj = new window.VerifyES5(
				loginFunc,
				verifiedCallback,
				isExcuteVerfied,
				closeCallback,
				sync,
				successReport
			);
			verifyObj.verify();
		},
	});

	return {
		verify: function () {},
	};
};

window.RNV = verifyFunc;
