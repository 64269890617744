(function() {
	var _w = window,
		_d = document,
		_l = _w.location;
	var gName = "__shequstat";
	var idatajq = window.jQuery || window.Zepto;
	//var dr_svr = _l.protocol + "//api.baike." + (_l.hostname.indexOf('.sogou.com') > -1 ? 'sogou' : 'sogo') + ".com/dr/p1.gif";
	//var pr_svr = _l.protocol + "//api.baike." + (_l.hostname.indexOf('.sogou.com') > -1 ? 'sogou' : 'sogo') + ".com/pr/pingd";
	var dr_svr = _l.protocol + "//api.baike.sogou.com/dr/p1.gif";
	var pr_svr = _l.protocol + "//api.baike.sogou.com/pr/pingd";

	var stat = {
		server: dr_svr,
		errServer: pr_svr + "?srctype=exception",
		cookiePrefix: "sqstat_",
		imgArr: [],
		cookie: "",
		query: "",
		hostname: _l.hostname,
		//referrer: _d.referrer,
		referrer: window._shequLocationHref || document.referrer,
		domain: "",
		cookieInfo: {},
		queryInfo: {},
		divHandlers: [],
		data: {},
		gatherTypes: {
			"ss_c": "div"
		},
		groupTypes: {
			"ch": "ch",
			"pid": "pid"
		},
		persistentTypes: {
			"pid": {
				"entr": "ss_pidf"
			},
			"cid": {
				"entr": "ss_cidf"
			}
		},
		cookieParam: ["ssuid", "pid", "cid", "ss_pidf", "ss_cidf", "bk_guid"/*, "p_skey", "p_luin", "p_lskey", "sgid", "psp_sgid", "wx_unid"*/],
		kvSplit: function(str, outerSep, innerSep) {
			if (typeof str != "string" || str === "") {
				return {}
			}
			outerSep = outerSep || "&";
			innerSep = innerSep || "=";
			var rObj = {};
			var outerArr = str.split(outerSep);
			for (var i = 0; i < outerArr.length; ++i) {
				if (outerArr[i].length == 0) {
					continue
				}
				var item = outerArr[i];
				var sepPos = item.indexOf(innerSep);
				if (sepPos < 0) {
					continue
				}
				var key = item.substring(0, sepPos);
				var val = item.substring(sepPos + innerSep.length);
				rObj[key] = val
			}
			return rObj
		},
		getDomain: function(hostname) {
			if (hostname === undefined && this.domain) {
				return this.domain
			}
			if (hostname === undefined) {
				hostname = this.hostname
			}
			var tArr = hostname.split(".");
                        var domain = "";
			if (tArr[tArr.length - 1].match(/^\d+$/)) {
				domain = hostname
			} else {
				domain = tArr.slice(-2).join(".")
			}
			if (hostname === this.hostname) {
				this.domain = domain
			}
			return domain
		},
		getCookie: function(name) {
			if (_d.cookie !== this.cookie) {
				this.cookie = _d.cookie;
				this.cookieInfo = this.kvSplit(_d.cookie, "; ")
			}
			return (this.cookieInfo[name]) ? decodeURIComponent(this.cookieInfo[name]) : this.cookieInfo[name]
		},
		getQuery: function(key) {
			if (_l.search !== this.query) {
				this.query = _l.search;
				this.queryInfo = this.kvSplit(_l.search.substring(1))
			}
			return this.queryInfo[key]
		},
		setCookie: function(name, value, max_age, path, domain) {
			path = path || "/";
			domain = domain || this.getDomain();
			var getExpires = function(max_age) {
					var date = new Date();
					if (max_age == "unlimited") {
						date.setFullYear(2038, 0, 1)
					} else {
						if (typeof max_age == "number") {
							if (max_age <= 0) {
								date.setFullYear(1970, 1, 1)
							} else {
								date.setTime(date.getTime() + max_age * 1000)
							}
						}
					}
					return date.toGMTString()
				};
			var item = name + "=" + encodeURIComponent(value) + (max_age || max_age === 0 ? ("; expires=" + getExpires(max_age)) : "") + (path ? ";path=" + path : "") + (domain ? ";domain=" + domain : "");
			_d.cookie = item
		},
		delCookie: function(name, path, domain) {
			this.setCookie(name, "", 0, path, domain)
		},
		loopDivs: function() {
			var divSet = _d.getElementsByTagName("div");
			for (var i = 0; i < divSet.length; ++i) {
				for (var j = 0; j < this.divHandlers.length; ++j) {
					this.divHandlers[j](divSet[i])
				}
			}
		},
		regDivHandler: function(func) {
			this.divHandlers.push(func)
		},
		regDivHandlers: function() {
			for (var type in this.gatherTypes) {
				this.regDivHandler(this.getGatherFunc(type))
			}
			for (var type in this.groupTypes) {
				this.regDivHandler(this.getGroupFunc(type))
			}
		},
		getGatherFunc: function(type, sep) {
			var sep = sep || "^";
			var data = this.data;
			return function(div) {
				var attrVal = div.getAttribute(type);
				if (attrVal) {
					data[type] = data[type] === undefined ? "" : data[type];
					data[type] += (data[type] === "" ? "" : sep) + attrVal
				}
			}
		},
		getGroupFunc: function(type, domain) {
			var domain = domain || this.getDomain();
			var stat = this;
			return function(div) {
				var attrVal = div.getAttribute(type);
				if (!attrVal) {
					return
				}
				var strToAdd = type + "=" + attrVal;
				var urls = div.getElementsByTagName("a");
				var pattern = new RegExp("[&?]" + type + "=");
				for (var i = 0; i < urls.length; ++i) {
					var url = urls[i];
					if (stat.getDomain(url.hostname) == domain) {
						if (pattern.test(url.search)) {
							continue
						}
						url.href = " " + url.href + (url.search ? "&" : "?") + strToAdd
					}
				}
			}
		},
		genSsuid: function() {
			var c = new Date().getUTCMilliseconds();
			return (Math.round(Math.random() * 2147483647) * c) % 10000000000
		},
		submit: function(obj, server) {

			// 挂载全局配置上报
			var gConf = window._gConf || {};
			gConf.bk_guid = gConf.guid || stat.getCookie("bk_guid");
			delete gConf.guid;
			Object.assign && Object.assign(obj, gConf);

			server = server || this.server;
			var queryString = "";
			if (obj["ref"] !== undefined) {
				queryString = "ref=" + obj["ref"];
				delete(obj["ref"])
			}
			else {
				queryString = "ref=" + encodeURIComponent(this.referrer);
			}
			for (var k in obj) {
				queryString += (queryString ? "&" : "") + k + "=" + obj[k];
			}
			if (navigator && navigator.connection) {
                if (/iphone|ipad|android/.test(navigator.userAgent.toLowerCase())) {
					var network = navigator.connection.type ? navigator.connection.type : 'unknown';
					queryString += (queryString ? "&" : "") + "network=" + network;
                }
				else {
					queryString += (queryString ? "&" : "") + "network=";
				}
            }
			else {
				queryString += (queryString ? "&" : "") + "network=";
			}
			if(window.screen) {
				queryString += (queryString ? "&" : "") + "screen=" + window.screen.width + '_' + window.screen.height + "&dpr=" + (window.devicePixelRatio || 1);
			}
			else {
				queryString += (queryString ? "&" : "") + "screen=0_0&dpr=0";
			}
			queryString += (queryString ? "&" : "") + "rand=" + Math.random();
			var src = server + "?" + queryString;
			var c = new Image();
			var len = this.imgArr.push(c);
			var imgArr = this.imgArr;
			c.onload = (c.onerror = function() {
				imgArr[len - 1] = null
			});
			c.src = src;
			c = null
		},
		setPersistentParams: function() {
			for (var type in this.persistentTypes) {
				var props = this.persistentTypes[type];
				var flagName = props["entr"] || (this.cookiePrefix + type + "_f");
				var valInQuery = this.getQuery(type);
				if (valInQuery) {
					if (valInQuery == this.getCookie(type)) {
						this.setCookie(flagName, "1")
					} else {
						this.delCookie(flagName);
						this.setCookie(type, valInQuery)
					}
				} else {
					if (this.getCookie(type)) {
						this.setCookie(flagName, "1")
					}
				}
			}
		},
		isMobile: function () {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile Safari/i.test(navigator.userAgent)
        },
		getSc: function() {
			//sc must < 12
			var sc = "unknown";
			var domainname = _l.hostname.toLowerCase();
			if(domainname.indexOf('wenwen') > -1) {
				sc = this.isMobile() ? "wapask" : "ask";
			}
			else if(domainname.indexOf('baike') > -1) {
				sc = this.isMobile() ? "wapbaike" : "baike";
				if(_l.pathname.indexOf('/kexue') > -1) {
					sc = this.isMobile() ? "wapkexue" : "kexue";
				}
				else if(_l.pathname.indexOf('/zhuanti') > -1) {
					sc = this.isMobile() ? "wapzhuanti" : "zhuanti";
					if(_l.href.indexOf('preview=1') > -1) {
						sc = this.isMobile() ? "wapprezt" : "prezt";
					}
				}
			}
			else if(domainname.indexOf('zhinan') > -1) {
				sc = this.isMobile() ? "wapzhinan" : "zhinan";
			}
			else if(domainname.indexOf('ld.sogo') > -1 || domainname.indexOf('luedong') > -1 || domainname.indexOf('ldapi') > -1) {
				sc = this.isMobile() ? "wapld" : "ld";
			}
			else if(domainname.indexOf('zhuanjia') > -1) {
				if(_l.pathname.indexOf('/mei') > -1) {
					sc = this.isMobile() ? "wapyimei" : "yimei";
				} else {
					sc = this.isMobile() ? "wapzhuanjia" : "zhuanjia";
				}
			}
			else if(domainname.indexOf('lvshi') > -1) {
				sc = this.isMobile() ? "waplvshi" : "lvshi";
			}
			else if(domainname.indexOf('kexue') > -1) {
				sc = this.isMobile() ? "wapkexue" : "kexue";
			}
			else if(domainname.indexOf('zhuanti') > -1) {
				sc = this.isMobile() ? "wapzhuanti" : "zhuanti";
				if(_l.href.indexOf('preview=1') > -1) {
					sc = this.isMobile() ? "wapprezt" : "prezt";
				}
			}
			/*else if(domainname.indexOf('wenda') > -1) {
				sc = this.isMobile() ? "wapwenda" : "wenda";
			}
			else if(domainname.indexOf('ldvideo') > -1) {
				sc = this.isMobile() ? "wapldvideo" : "ldvideo";
			}
			else if(domainname.indexOf('taowen') > -1) {
				sc = this.isMobile() ? "waptaowen" : "taowen";
			}
			else if(domainname.indexOf('wenzhen') > -1) {
				sc = this.isMobile() ? "wapwenzhen" : "wenzhen";
			}*/
			return sc;
		},
		run: function(data) {
			if (typeof(data) != "object") {
				data = {}
			}
			this.setPersistentParams();
			this.regDivHandlers();
			this.loopDivs();
			if (!this.getCookie("ssuid")) {
				this.setCookie("ssuid", this.genSsuid(), "unlimited")
			}
			data["ver"] = 5;
			data["ref"] = encodeURIComponent(this.referrer);
			data["sc"] = this.getSc();
			//data["searchId"] = window.__searchId || 0;
			/*if (navigator && navigator.connection) {
                //just wap
                if (/iphone|ipad|android/.test(navigator.userAgent.toLowerCase())) {
                    data["network"] = navigator.connection.type ? navigator.connection.type : 'unknown';
                }
            }*/
			if (window.performance && window.performance.navigation) {
                //0 点击链接、地址栏输入、表单提交、脚本操作等方式加载  1 通过 刷新 按钮或者 location.reload() 方法加载  2 通过 前进 或 后退 按钮加载   255 网页通过其它 任何可执行的来源 加载
                data["navitype"] = window.performance.navigation.type;
            }
			else {
				data["navitype"] = 'unknown';
			}
			for (var type in this.gatherTypes) {
				var name = this.gatherTypes[type];
				if (this.data[type]) {
					data[name] = this.data[type]
				}
			}
			for (var i = 0; i < this.cookieParam.length; i++) {
				var k = this.cookieParam[i];
				data[k] = this.getCookie(k)
			}
			/*if(window.screen) {
				data["screen"] = window.screen.width + '_' + window.screen.height;
			}
            data["dpr"] = window.devicePixelRatio || 1;*/
			
			//can't use
			/*if ((this.getCookie("p_uin")&&this.getCookie("p_skey"))||(this.getCookie("p_luin")&&this.getCookie("p_lskey"))) {
				data["login"]="qq"
			}
			else if(this.getCookie("sgid")&&this.getCookie("psp_sgid")){
				data["login"]="passport"
			}
			else if(this.getCookie("wx_unid")){
				data["login"]="weixin"
			}
			else
			{
				data["login"]=0
			}*/

			this.submit(data)
		},
		checkSsuid: function() {
			if (!this.getCookie("ssuid")) {
				this.setCookie("ssuid", this.genSsuid(), "unlimited")
			}
		},
		/**
         * 是否上报停留时长，翻屏数
         * 需上报页面：
         *  百科词条页；
         *  问问首页、详情页；
         *  指南详情页、分类、首页；
         *  略懂详情页、列表页、首页、兴趣广场页；
         *  专家首页、详情页;
         *  律师首页，律师列表，最新资讯页，法律法规页，问答页，文章页，音频页，视频页；
         */
        isReportScreen: function() {
			var sc = this.getSc();
            //var hostname = _l.hostname;
            var pathname = _l.pathname;
            /*if (!hostname || !pathname) {
                return false;
            }*/
            if (sc === "zhuanjia" || sc === "wapzhuanjia" || sc === "yimei" || sc === "wapyimei") {
			//if(/zhuanjia.(sogou|sogo).com/i.test(hostname)) {
                return /\/detail\/[\S]+\.html/i.test(pathname) || /^\/lvshi\/*$/i.test(pathname) || /^\/yisheng\/*$/i.test(pathname) || /^\/mei\/*/i.test(pathname);
            }
			else if (sc === "baike" || sc === "wapbaike") {
                return /^\/v\d+\.htm/i.test(pathname) || /lemmaInfo\/*$/i.test(pathname) || /fullLemma\/*$/i.test(pathname) || /^\/m\/v\d+\.htm/i.test(pathname) || /collection\/*$/i.test(pathname) || /picBooklet\/*$/i.test(pathname) || /albumList\/*$/i.test(pathname) || /PicBooklet\.v\/*$/i.test(pathname) || /snapshot\/*$/i.test(pathname) || /tvAndMovieVideoList\/*$/i.test(pathname) || /mainStaff\/*$/i.test(pathname) || /bookCatalogList\/*$/i.test(pathname) || /authorBooks\/*$/i.test(pathname) || /enterprise\/*$/i.test(pathname) || /relatedEnterprise\/*$/i.test(pathname);
            }
			else if (sc === "ask" || sc === "wapask") {
                return /\/z\/q(\d+)\.htm/i.test(pathname) || /^\/question\/*$/i.test(pathname) || /\/cate\/home/i.test(pathname) || pathname == '/';
            }
			else if (sc === "zhinan" || sc === "wapzhinan") {
                return /^\/(guide)?\/*/i.test(pathname) || /^\/guide\/detail\/*/i.test(pathname) || /^\/guide\/cate\/*/i.test(pathname);
            }
			else if (sc === "ld" || sc === "wapld") {
                return /^\/(m)?\/*$/.test(pathname) || /^(\/m)?\/catesquare\/*$/i.test(pathname) || /^(\/m)?\/cate\/*$/i.test(pathname) || /^(\/m)?\/question\/*/i.test(pathname) || /^(\/m)?\/article\/*/i.test(pathname);
            }
			/*else if (sc === "wenda" || sc === "wapwenda") {
                return /\/k\/c(\d+)\.htm/.test(pathname);
            }*/
			else if (sc === "lvshi" || sc === "waplvshi") {
                return /^\/$/.test(pathname) || /^\/lawyer\/list\.html/.test(pathname) || /^\/import-question\/list\.html$/.test(pathname) || /^\/regulations\/list\.html$/.test(pathname) || /^\/import-question\/detail\/\w+\.html$/.test(pathname) || /^\/article\/detail\/\w+\.html$/.test(pathname) || /^\/audio\/detail\/\w+\.html$/.test(pathname) || /^\/video\/detail\/\w+\.html$/.test(pathname);
            }
			else if (sc === "kexue" || sc === "wapkexue") {
                return /^\/s\d+\.htm/i.test(pathname) || /^\/kexue\/m\/s\d+\.htm/i.test(pathname) || /^\/m\/s\d+\.htm/i.test(pathname) || /^\/kexue\/d\d+\.htm/i.test(pathname);
            }
			else {
                return false;
            }
        },
		/**
         * 滚屏数停留时长统计
         * @param screens
         * @param stayTime
         */
        reportScSt: function (screens, stayTime) {
            if (screens && stayTime) {
				this.checkSsuid();
				var sc = this.getSc();
				var data = {
					"srctype": "getscreens",
					"lurl": escape(_l),
					"sc": sc,
					"screens": screens,
					"staytime": stayTime,
					"ssuid": this.getCookie("ssuid"),
					"sw_uuid": this.getCookie("sw_uuid"),
					"wuid": this.getCookie("wuid"),
					"suv": this.getCookie("SUV"),
					"yyid": this.getCookie("YYID"),
					"orig": this.isMobile() ? 254 : 253
				};
				this.submit(data, pr_svr)
            }
        },
		throttleSc: function (fn, threshhold, scope) {
            threshhold || (threshhold = 300);
            var last,
                deferTimer;
            return function () {
                var context = scope || this;

                var now = +new Date,
                    args = arguments;
                if (last && now < last + threshhold) {
                    // hold on to it
                    clearTimeout(deferTimer);
                    deferTimer = setTimeout(function () {
                        last = now;
                        fn.apply(context, args);
                    }, threshhold);
                } else {
                    last = now;
                    fn.apply(context, args);
                }
            };
        },
		/**
         * 滚屏数与页面停留时长统计
		 * 手按在屏幕上不离开，滑动的时候不会触发此事件，在手移开的时候才触发。
         */
        screenAndStaytime: function () {
            var screens = 1,
                enterTime = new Date().getTime();
            idatajq(window).on('scroll', stat.throttleSc(function () {
                screens = Math.max(Math.ceil(idatajq(window).scrollTop() / idatajq(window).height()) + 1, screens);
            }, 300));
            idatajq(window).on('unload', function () {
                var stayTime = new Date().getTime() - enterTime;
                stat.reportScSt(screens, stayTime);
            });
        },
		stget: function(ch, expid, tagid, env) {
			var sc = "";
                        stat.checkSsuid();
			sc = stat.getSc();
			/*network = 'unknown';
			if (navigator && navigator.connection) {
                if (/iphone|ipad|android/.test(navigator.userAgent.toLowerCase())) {
                    network = navigator.connection.type ? navigator.connection.type : 'unknown';
                }
            }*/
			var data = {
				"srctype": "getsret",
				//"lurl": escape(_l),
				"ch": ch,
				"sc": sc,
				"ssuid": stat.getCookie("ssuid"),
				//"network": network,
				"orig": stat.isMobile() ? 254 : 253
			};
			stat.submit(data, pr_svr)
			
			var expid = expid || "0";
			var tagid = tagid || "0";
			var env = env || "formal";
			//if (expid !== "0" && /^\d+$/.test(expid)) {
			if (expid !== "0" && /^[0-9,]*$/.test(expid)) {
				var data_exp = {
				    "srctype": "getcost",
				    //"lurl": escape(_l),
				    "para": ch,
				    "expid": expid,
				    "tagid": tagid,
					"env": env,
				    "sc": sc,
				    "ssuid": stat.getCookie("ssuid"),
				    "orig": stat.isMobile() ? 254 : 253
			    };
			    stat.submit(data_exp, pr_svr)
			}
		},
		collectget: function(ch, lemmaid, collectid, env) {
			var sc = "";
            stat.checkSsuid();
			sc = stat.getSc();
			var lemmaid = lemmaid || "0";
			var collectid = collectid || "0";
			var env = env || "formal";
			var data = {
			    "srctype": "getcollect",
			    //"lurl": escape(_l),
			    "ch": ch,
			    "lemmaid": lemmaid,
			    "collectid": collectid,
				"env": env,
			    "sc": sc,
			    "ssuid": stat.getCookie("ssuid"),
			    "orig": stat.isMobile() ? 254 : 253
			};
			stat.submit(data, pr_svr)
		},
		apget: function(ap, para, sc) {
			stat.checkSsuid();
			sc = stat.getSc();
			
			var para = para || "";
			var data = {
				"srctype": "getap",
				//"lurl": escape(_l),
				"ap": ap,
				"para": escape(para),
				"sc": sc,
				"ssuid": stat.getCookie("ssuid"),
				"orig": stat.isMobile() ? 254 : 253
			};
			stat.submit(data, pr_svr)
		},
		costget: function(para, exp, tagid) {
			var sc = "";
			stat.checkSsuid();
			sc = stat.getSc();
			
			var exp = exp || "0";
			var tagid = tagid || "0";
			if (exp !== "0" && /^[a-zA-Z0-9_,]+$/.test(exp)) {
				var data = {
					"srctype": "getcostv2",
					"para": para,
					"exp": exp,
					"tagid": tagid,
					"sc": sc,
					"ssuid": stat.getCookie("ssuid"),
					"orig": stat.isMobile() ? 254 : 253
				};
				stat.submit(data, pr_svr)
			}
		},
		videoget: function(type, pageid, videoid, interval, loadstamp) {
			var sc = "";
			stat.checkSsuid();
			sc = stat.getSc();
			
			var type = type || "";
			var pageid = pageid || 0;
			var videoid = videoid || 0;
			var interval = interval || 0;
			var loadstamp = loadstamp || 0;
			var data = {
				"srctype": "getvideo",
				//"lurl": escape(_l),
				"type": type,
				"pageid": pageid,
				"videoid": videoid,
				"interval": interval,
				"loadstamp": loadstamp,
				"sc": sc,
				"ssuid": stat.getCookie("ssuid"),
				"orig": stat.isMobile() ? 254 : 253
			};
			stat.submit(data, pr_svr)
		},
		browsetimeget: function(type, pageid, screens, staytime, loadstamp) {
			var sc = "";
			stat.checkSsuid();
			sc = stat.getSc();
			
			var type = type || "";
			var pageid = pageid || 0;
			var screens = screens || 0;
			var staytime = staytime || 0;
			var loadstamp = loadstamp || 0;
			var data = {
				"srctype": "getbrowsetime",
				//"lurl": escape(_l),
				"type": type,
				"pageid": pageid,
				"screens": screens,
				"staytime": staytime,
				"loadstamp": loadstamp,
				"sc": sc,
				"ssuid": stat.getCookie("ssuid"),
				"orig": stat.isMobile() ? 254 : 253
			};
			stat.submit(data, pr_svr)
		},
		consumreport: function(moduleid,cataid,classid,pagetype,pageid,trigger,target,targetdetail,special,eventname, videoPage) {
			// 兼容对象入参
			var params = {};
			if(typeof moduleid === 'object') {
				params = moduleid;
			} else {
				params.moduleid = moduleid;
				params.cataid = cataid;
				params.classid = classid;
				params.pagetype = pagetype;
				params.pageid = pageid;
				params.trigger = trigger;
				params.target = target;
				params.targetdetail = targetdetail;
				params.special = special;
				params.eventname = eventname;
				params.videoPage = videoPage;
			}

			// 视频百科页面不走该路上报
			var videoPage = params.videoPage || false;
			if (!videoPage && _l && _l.hash === '#videoBaike'){
				return;
			}

			// 向前兼容默认值
			['moduleid', 'cataid', 'classid', 'pageid', 'trigger'].forEach(function(item) {
				params[item] = params[item] || 0;
			});
			['pagetype', 'target', 'targetdetail', 'special', 'eventname'].forEach(function(item) {
				params[item] = params[item] || '';
			});

			stat.checkSsuid();
			params.srctype = 'consumreport';
			params.sc = stat.getSc();
			params.ssuid = params.ssuid || stat.getCookie("ssuid");
			params.orig = stat.isMobile() ? 254 : 253;
			params.bk_guid = params.guid || stat.getCookie("bk_guid");
			delete params.guid;
			stat.submit(params, pr_svr)
		}
	};
	window[gName] = stat;
	window["stget"] = stat.stget;
	window["collectget"] = stat.collectget;
	window["apget"] = stat.apget;
	window["costget"] = stat.costget;
	window["videoget"] = stat.videoget;
	window["browsetimeget"] = stat.browsetimeget;
	window["consumreport"] = stat.consumreport;
	window["statrun"] = stat.run.bind(stat);
	stat.run();
	if (idatajq) {
		try {
			stat.isReportScreen() && stat.screenAndStaytime();
		} catch (e) {

		}
	}
	if (window.lemmaInfo) {
		if (window.lemmaInfo.isNewLemmaCountFlag || window.lemmaInfo.thumbnailUrl) {
			stat.stget('newbaikelemma');
		}
	}

})();
