//问问全业务线必须加载的js，包含template、安全Cookie读写策略等问问所有业务下都会用到的通用功能
import "./ajax";
import "./realNameVerify";
import "../connectLogin/wap/baike/login.css";

import * as domainUtils from "../../../lib/domain";
import * as cookie from "../cookie/entry";
const mainDomain = domainUtils.getSogouOrSogo();

import "./pubsub";
const LOGIN$ = window.jQuery || window.Zepto;
const $win = LOGIN$(window);

window.WKRWDOMAIN = function (urlStr) {
	if (typeof urlStr === "string") {
		if (location.host.indexOf(".sogou.com") > -1) {
			return urlStr.replace(".sogo.com", ".sogou.com");
		} else {
			return urlStr.replace(".sogou.com", ".sogo.com");
		}
	}

	return urlStr;
};

function getHourStamp() {
	const date = new Date();
	const year = date.getFullYear();
	const month = date.getMonth() + 1;
	const day = date.getDate();
	const hour = date.getHours();
	return `${year}${month < 10 ? "0" + month : month}${
		day < 10 ? "0" + day : day
	}${hour < 10 ? "0" + hour : hour}`;
}
function isPC(ua) {
	if (!ua) return true;

	const isWindowsPhone = /(?:Windows Phone)/i.test(ua),
		isSymbian = /(?:SymbianOS)/i.test(ua) || isWindowsPhone,
		isAndroid = /(?:Android)/i.test(ua),
		isFireFox = /(?:Firefox)/i.test(ua),
		isTablet =
			/(?:iPad|PlayBook)/i.test(ua) ||
			(isAndroid && !/(?:Mobile)/i.test(ua)) ||
			(isFireFox && /(?:Tablet)/i.test(ua)),
		isPhone = /(?:iPhone)/i.test(ua) && !isTablet,
		isPcMiniProgram = /(?:MiniProgramEnv\/(Mac|Windows))/i.test(ua),
		isPc = !isPhone && !isAndroid && !isSymbian && !isPcMiniProgram;
	return isPc;
}

(function ($) {
	if (!$) {
		return false;
	}

	$.loadScript = (function () {
		const scriptCache = {}; //缓存script标签,标识标签是否被添加到页面中
		const funList = {}; //函数执行队列
		const loadSucCache = {}; //标识某个script url加载成功
		const whiteDomainList = [
			"hhy.sogoucdn.com",
			"dlweb.sogoucdn.com",
			"cache.soso.com",
		]; //添加crossorigin=anonymous的域名白名单
		const defaultConfig = {
			url: "", //url必须传
			scriptCharset: "", //编码
			success: null,
			error: null,
		};

		const name = "sg_forbid_user";
		const ca = document.cookie.split(";");
		let cookieName;
		for (let i = 0; i < ca.length; i++) {
			const c = ca[i].trim();
			if (c.indexOf(name) == 0) {
				cookieName = c.substring(name.length + 1, c.length);
				break;
			}
		}
		const domainAElement = document.createElement("a");
		domainAElement.href = window.location.href;
		let isWenwenOrBaike = false;
		if (
			domainAElement.host.indexOf("wenwen.sogou.com") > -1 ||
			domainAElement.host.indexOf("baike.sogou.com") > -1
		) {
			isWenwenOrBaike = true;
		}

		const ua = window.navigator.userAgent;
		const isPc = isPC(ua);
		const app = isPc ? "Pc" : "Wap";

		if (cookieName == "1" && app == "Pc") {
			const _width = 550; //iframe宽度
			const _height = 360; //iframe高度

			const loginDialogTplArr = [];
			const wrapClassName =
				"new-login " + (isWenwenOrBaike ? " no-bg" : "");
			loginDialogTplArr.push(
				'<div id="login_iframe_container" class="' +
					wrapClassName +
					'" style="top:' +
					($win.height() - _height) / 2 +
					"px;left:" +
					($win.width() - _width) / 2 +
					'px;"><div class="forbid-title">部分账号系统升级中，请耐心等待，感谢～</div>'
			);
			loginDialogTplArr.push(
				'<i class="close-btn loginClose forbid"></i>'
			);
			loginDialogTplArr.push("</div>");

			LOGIN$("body").append(
				'<div id="login_mask" style="width:100%"></div>' +
					loginDialogTplArr.join("")
			);
			cookie.delCookie("sg_forbid_user", "." + mainDomain + ".com", "/");
			setTimeout(() => {
				LOGIN$("#login_iframe_container").on(
					"click",
					".loginClose.forbid",
					function () {
						LOGIN$("#login_mask").remove();
						LOGIN$("#login_iframe_container").remove();
						window.WKSSO.callbackList = [];
					}
				);
			});
		} else if (cookieName == "1" && app == "Wap") {
			const layer = `<div class="onekey-fixed forbid" id="baikeLogin">
		<div class="onekey-login forbid">
		<div class="onekey-login-title forbid">部分账号系统升级中，请耐心等待，感谢～</div>
		</div>
        </div>`;
			cookie.delCookie("sg_forbid_user", "." + mainDomain + ".com", "/");
			LOGIN$("body").append(layer);
			// LOGIN$("#baikeLogin .onekey-close.forbid").click(function () {
			// 	LOGIN$("#baikeLogin").hide();
			// });
			setTimeout(function () {
				LOGIN$(".onekey-fixed.forbid").hide();
			}, 3000);
		}
		return function (config) {
			config = $.extend({}, defaultConfig, config);
			let url = config.url;
			if (!url) {
				return false;
			}

			if (
				url.indexOf(
					"//cache.soso.com/deploy/js/lib/share/mobileShare.js"
				) > -1 ||
				url.indexOf(
					"//hhy.sogoucdn.com/js/common/share/mobileShare.js"
				) > -1 ||
				url.indexOf(
					"//hhy.sogoucdn.com/js/common/realNameVerify/main.js"
				) > -1
			) {
				url = url.replace(/\.js$/, "_" + getHourStamp() + ".js");
			}

			if (loadSucCache[url]) {
				typeof config.success === "function" && config.success();
				return false;
			}
			if (typeof config.success === "function") {
				funList[url]
					? funList[url].push(config.success)
					: (funList[url] = [config.success]);
			}

			if (!scriptCache[url]) {
				//script url初次被加载
				scriptCache[url] = true;
				let script = document.createElement("script");
				const head = document.getElementsByTagName("head")[0];
				if (config.scriptCharset) {
					script.charset = config.scriptCharset;
				}
				script.async = true;
				script.src = url;

				//判断是否添加crossorigin=anonymous
				const aEle = document.createElement("a");
				aEle.href = url;
				const curHost = aEle.host;
				if (whiteDomainList.indexOf(curHost) > -1) {
					script.crossOrigin = "anonymous";
				}

				script.onload = script.onreadystatechange = function () {
					if (
						!script.readyState ||
						/loaded|complete/.test(script.readyState)
					) {
						script.onload = script.onreadystatechange = null;
						script = null;
						loadSucCache[url] = true; //加载成功
						if (funList[url] && funList[url].length > 0) {
							for (
								let i = 0, len = funList[url].length;
								i < len;
								i++
							) {
								typeof funList[url][i] === "function" &&
									funList[url][i]();
							}
						}
					}
				};

				script.onerror = function () {
					scriptCache[url] = false; //第一次加载失败时，后面再次出发的时候依然会尝试加载
					script.onerror = null;
					script = null;
					typeof config.error === "function" && config.error();

					// js 加载错误多为地域原因导致,上报意义不大
					// window.badjs.report({
					//     url: url,
					//     stack: "loadJSEx",
					// }, EX_TYPE.loadJSEx);
				};
				head.appendChild(script);
			}
		};
	})();
	// 引入腾讯防水墙防刷sdk http://manage.captcha.oa.com/captcha/index.html#/gettingStart
	require('./tCaptcha');
})(window.jQuery || window.Zepto);
